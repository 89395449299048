<template>
  <v-card class="py-3" flat outlined>
    <v-card-title v-if="label">
      {{ label }}
    </v-card-title>
    <v-layout justify-center>
      <v-img
        :src="previewImg"
        :aspect-ratio="2"
        v-if="value"
        contain
        max-width="200"
      />
    </v-layout>
    <v-layout justify-center>
      <v-btn color="success" class="mt-3" @click="$refs.inputUpload.click()">
        {{ buttonText }}
      </v-btn>
      <input
        v-show="false"
        ref="inputUpload"
        type="file"
        @change="load"
        accept="image/*"
      />
    </v-layout>
  </v-card>
</template>

<script>
import { getRandomPreSignedUrl, uploadToS3 } from "@/services/aws-service";
export default {
  props: {
    value: {
      value: String,
      default: "",
    },
    label: {
      value: String,
      default: "",
    },
  },
  data: () => ({
    file: "",
    previewImg: "",
  }),
  watch: {
    value(value) {
      this.previewImg = value;
    },
  },
  computed: {
    buttonText() {
      return (this.value ? "Alterar" : "Nova") + " Imagem";
    },
  },
  created() {
    this.previewImg = this.value;
  },
  methods: {
    /* handleCompressedImage() {
      this.previewImg = LzString.decompressFromUTF16(this.value);
    }, */
    load() {
      if (this.$refs.inputUpload.files) {
        this.file = this.$refs.inputUpload.files[0];
       // if (!this.validate()) return;
        const fr = new FileReader();
        fr.readAsDataURL(this.file);
        fr.addEventListener("load", () => {
          this.upload();
        });
      }
    },
    async generateUrl() {
      const {
        data: { resourceUrl, key, contentType, url },
      } = await getRandomPreSignedUrl();
      return {
        resourceUrl,
        key,
        contentType,
        url,
      };
    },
    async upload() {
      try {
        const { resourceUrl, key, contentType, url } = await this.generateUrl();
        await uploadToS3({ image: this.file, key, url, contentType });
        this.previewImg = resourceUrl;
        this.$emit("input", resourceUrl);
      } catch (error) {
        this.$toasted.global.defaultError();
      }
    },
    validate() {
      const sizeInKb = this.file.size / 1000;
      const maxSizeInKb = 61;
      if (sizeInKb > maxSizeInKb) {
        this.$toasted.global.error({
          message: "Imagem acima de 40kb não permitida",
        });
        return false;
      }

      return true;
    },
  },
};
</script>

<style></style>
